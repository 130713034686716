import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Search } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import debounce from 'lodash.debounce';
import './FindDealerPage.css';

export default function FindDealerPage() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [dealers, setDealers] = useState([]);
  const [filteredDealers, setFilteredDealers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const suggestionsRef = useRef(null);
  const searchContainerRef = useRef(null);

  const provinces = [
    'Eastern Cape',
    'Free State',
    'Gauteng',
    'KwaZulu-Natal',
    'Limpopo',
    'Mpumalanga',
    'Northern Cape',
    'North West',
    'Western Cape'
  ];

  const fetchDealers = async (region = '') => {
    setLoading(true);
    try {
      const endpoint = `https://dealer.carmag.co.za/api.php?getdealers=1${
        region ? `&Region=${encodeURIComponent(region)}` : ''
      }`;
      
      const response = await axios.get(endpoint);
      
      if (response.data && response.data.listings) {
        const dealersList = Array.isArray(response.data.listings) 
          ? response.data.listings 
          : [response.data.listings];
        setDealers(dealersList);
        
        if (searchTerm) {
          filterDealersBySearchTerm(searchTerm, dealersList);
        }
      } else {
        setDealers([]);
        setFilteredDealers([]);
      }
      setError(null);
    } catch (err) {
      setError('Failed to fetch dealers');
      console.error('Error:', err);
      setDealers([]);
      setFilteredDealers([]);
    } finally {
      setLoading(false);
    }
  };

  const filterDealersBySearchTerm = (term, dealersList = dealers) => {
    if (!term) {
      setFilteredDealers([]);
      setShowSuggestions(false);
      return;
    }

    const searchTermLower = term.toLowerCase();
    const filtered = dealersList.filter((dealer) =>
      dealer.dealershipname?.toLowerCase().startsWith(searchTermLower)
    );

    setFilteredDealers(filtered);
    setShowSuggestions(true);
  };

  const debouncedFilter = useCallback(
    debounce((term) => filterDealersBySearchTerm(term), 300),
    [dealers]
  );

  useEffect(() => {
    fetchDealers();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current && 
        !searchContainerRef.current.contains(event.target) &&
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleProviceSelect = (province) => {
    const newRegion = province === selectedRegion ? '' : province;
    setSelectedRegion(newRegion);
    
    if (newRegion) {
      const formattedRegion = newRegion.toLowerCase().replace(/\s+/g, '-');
      navigate(`/find-dealer/${formattedRegion}`);
    }
  };

  return (
    <div className="find-dealer-page">
      <div className="hero-section">
        <div className="hero-content">
          <h1 className="hero-title">Find a Dealer</h1>
          <p className="hero-subtitle">Browse authorized car dealers in South Africa</p>
        </div>
      </div>

      <div className="form-section">
        <h2 className="form-section-header">Filter Dealers by Province</h2>
        <div className="province-buttons">
          {provinces.map((province) => (
            <button
              key={province}
              className={`province-button ${selectedRegion === province ? 'active' : ''}`}
              onClick={() => handleProviceSelect(province)}
              type="button"
            >
              {province}
            </button>
          ))}
        </div>
      </div>

      {loading && <div className="loading-state">Loading...</div>}
      {error && <div className="error-state">{error}</div>}
    </div>
  );
}