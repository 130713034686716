import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { formatDate } from '../utils';
import LoadingSpinner from './LoadingSpinner';
import './CarNewsPage.css';

const CarNewsPage = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://content.hsmdns.co.za/wp-json/wp/v2/posts/?categories=5&per_page=12&page=${page}&_embed`
        );
        
        const totalPages = Number(response.headers['x-wp-totalpages']);
        
        const processedArticles = response.data.map(article => ({
          ...article,
          excerpt: {
            ...article.excerpt,
            rendered: article.excerpt.rendered
              .replace(/<\/?[^>]+(>|$)/g, '')  // Remove HTML tags
              .split(' ')
              .slice(0, 20)  // Limit to first 20 words
              .join(' ') + '...'  // Add ellipsis
          }
        }));

        setArticles(prevArticles => 
          page === 1 ? processedArticles : [...prevArticles, ...processedArticles]
        );
        
        setHasMore(page < totalPages);
        setError(null);
      } catch (err) {
        setError('Failed to fetch news articles');
        console.error('Error fetching articles:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [page]);

  const loadMore = () => {
    if (!loading && hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  if (error) {
    return (
      <div className="news-page">
        <div className="content-wrapper">
          <div className="error-state">{error}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="news-page">
      <div className="news-header">
        <div className="content-wrapper">
          <h1 className="news-title">Latest Car News</h1>
          <p className="news-subtitle">Stay updated with the latest automotive news and reviews</p>
        </div>
      </div>

      <div className="content-wrapper">
        <div className="news-grid">
          {articles.map(article => (
            <Link 
              to={`/news/${article.slug}`}
              key={article.id} 
              className="news-card"
            >
              {article._embedded?.['wp:featuredmedia']?.[0] && (
                <div className="news-image-container">
                  <img
                    src={article._embedded['wp:featuredmedia'][0].source_url}
                    alt={article.title.rendered}
                    className="news-image"
                    loading="lazy"
                  />
                </div>
              )}
              <div className="news-content">
                <h2 
                  className="news-card-title"
                  dangerouslySetInnerHTML={{ __html: article.title.rendered }}
                />
                <div className="news-meta">
                  <span className="news-date">{formatDate(article.date)}</span>
                </div>
                <div 
                  className="news-excerpt"
                  dangerouslySetInnerHTML={{ __html: article.excerpt.rendered }}
                />
              </div>
            </Link>
          ))}
        </div>

        {loading && <div className="loading-state"><LoadingSpinner /></div>}

        {!loading && hasMore && (
          <div className="load-more">
            <button 
              onClick={loadMore} 
              className="load-more-button"
              disabled={loading}
            >
              Load More Articles
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CarNewsPage;