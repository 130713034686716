import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ContactFormModal.css';

const ContactFormModal = ({ isOpen, onClose, carData }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    region: '',
    message: '',
    sendAlerts: false
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const regions = [
    'Eastern Cape',
    'Free State',
    'Gauteng',
    'KwaZulu-Natal',
    'Limpopo',
    'Mpumalanga',
    'Northern Cape',
    'North West',
    'Western Cape'
  ];

  useEffect(() => {
    if (isOpen && carData) {
      const autoMessage = `I would like to check the availability of the ${carData.year} ${carData.make} ${carData.model} For Sale in ${carData.region}, ${carData.city}`;
      setFormData(prev => ({
        ...prev,
        message: autoMessage
      }));
    }
  }, [isOpen, carData]);

  useEffect(() => {
    if (!isOpen) {
      setFormData({
        name: '',
        email: '',
        mobile: '',
        region: '',
        message: '',
        sendAlerts: false
      });
      setErrors({});
      setIsSubmitting(false);
    }
  }, [isOpen]);

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }
    
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }
    
    if (!formData.mobile.trim()) {
      newErrors.mobile = 'Mobile number is required';
    }
    
    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (validateForm() && !isSubmitting) {
      setIsSubmitting(true);

      try {
        // Create URL-encoded form data
        const params = new URLSearchParams();
        params.append('action', 'send_lead');
        params.append('name', formData.name);
        params.append('email', formData.email);
        params.append('phone', formData.mobile);
        params.append('region', formData.region);
        params.append('message', formData.message);
        params.append('send_alerts', formData.sendAlerts ? '1' : '0');
        params.append('car_id', carData.id);
        params.append('dealer_id', carData.dealerid);
        params.append('stockNo', carData.stockNo);
        params.append('postlead', 'X');
        params.append('url', window.location.href);
        params.append('make', carData.make);
        params.append('model', carData.model);
        params.append('year', carData.year);
        params.append('price', carData.price);

        const response = await axios({
          method: 'post',
          url: 'https://dealer.carmag.co.za/app/app.php',
          data: params,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
            'Origin': 'https://dealer.carmag.co.za'
          }
        });

        if (response.data.success) {
          alert('Message sent successfully!');
          onClose();
        } else {
          throw new Error(response.data.message || 'Failed to send message');
        }
      } catch (error) {
        // Try alternative method if CORS fails
        try {
          const formElement = document.createElement('form');
          formElement.method = 'POST';
          formElement.action = 'https://dealer.carmag.co.za/app/app.php';
          document.body.appendChild(formElement);
          //formElement.target = '_blank';

          // Add form fields
          Object.entries({
            action: 'send_lead',
            xxx: 'adrian',
            name: formData.name,
            email: formData.email,
            phone: formData.mobile,
            region: formData.region,
            message: formData.message,
            send_alerts: formData.sendAlerts ? '1' : '0',
            car_id: carData.id,
            dealer_id: carData.dealerid,
            make: carData.make,
            model: carData.model,
            year: carData.year,
            price: carData.price
          }).forEach(([key, value]) => {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = value;
            formElement.appendChild(input);
          });

          document.body.appendChild(formElement);
          formElement.submit();
          document.body.removeChild(formElement);
          
          alert('Message sent successfully!');
          onClose();
        } catch (fallbackError) {
          console.error('Submission error:', fallbackError);
          alert('Error sending message. Please try again later.');
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-backdrop" onClick={handleBackdropClick}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <h2>Contact Dealer</h2>
          <button onClick={onClose} className="close-button">×</button>
        </div>

        <div className="car-info">
          <p>{carData?.year} {carData?.make} {carData?.model}</p>
          <p>{carData?.dealershipname}</p>
        </div>

        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-group">
            <input
              type="text"
              placeholder="Name*"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              disabled={isSubmitting}
            />
            {errors.name && <span className="error">{errors.name}</span>}
          </div>

          <div className="form-group">
            <input
              type="email"
              placeholder="Email*"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              disabled={isSubmitting}
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>

          <div className="form-group">
            <input
              type="tel"
              placeholder="Mobile*"
              value={formData.mobile}
              onChange={(e) => setFormData({ ...formData, mobile: e.target.value })}
              disabled={isSubmitting}
            />
            {errors.mobile && <span className="error">{errors.mobile}</span>}
          </div>

          <div className="form-group">
            <select
              value={formData.region}
              onChange={(e) => setFormData({ ...formData, region: e.target.value })}
              disabled={isSubmitting}
            >
              <option value="">-- Region --</option>
              {regions.map((region) => (
                <option key={`region-${region}`} value={region}>{region}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <textarea
              placeholder="Message*"
              rows="4"
              value={formData.message}
              onChange={(e) => setFormData({ ...formData, message: e.target.value })}
              disabled={isSubmitting}
            />
            {errors.message && <span className="error">{errors.message}</span>}
          </div>

          <div className="form-group checkbox">
            <input
              type="checkbox"
              id="sendAlerts"
              checked={formData.sendAlerts}
              onChange={(e) => setFormData({ ...formData, sendAlerts: e.target.checked })}
              disabled={isSubmitting}
            />
            <label htmlFor="sendAlerts">Send me alerts for similar cars</label>
          </div>

          <button 
            type="submit" 
            className="submit-button"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Sending...' : 'Send Message'}
          </button>

          <p className="terms">
            By continuing I understand and agree with Car Magazine's{' '}
            <a href="/terms-and-conditions">Terms & Conditions</a> and{' '}
            <a href="/privacy-policy">Privacy Policy</a>.
          </p>
        </form>
      </div>
    </div>
  );
};

export default ContactFormModal;