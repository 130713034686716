import React, { useState, useEffect } from 'react';
import PromotionContactForm from './PromotionContactForm';
import { getPromotions } from '../services/promotionsService';
import LoadingSpinner from './LoadingSpinner';

const FeaturedPromotions = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedPromo, setSelectedPromo] = useState(null);
  const [promotions, setPromotions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentMainIndex, setCurrentMainIndex] = useState(0);

  // Fetch promotions
  useEffect(() => {
    const fetchPromotions = async () => {
      try {
        const data = await getPromotions();
        setPromotions(data);
      } catch (err) {
        setError('Failed to fetch promotions');
      } finally {
        setLoading(false);
      }
    };

    fetchPromotions();
  }, []);

  // Auto rotation effect
  useEffect(() => {
    if (promotions.length === 0) return;

    const intervalId = setInterval(() => {
      setCurrentMainIndex((prevIndex) => (prevIndex + 1) % promotions.length);
    }, 30000); // 30 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [promotions.length]);

  const handlePromoClick = (promo) => {
    setSelectedPromo(promo);
    setIsFormOpen(true);
  };

  // Get the next 4 promotions after the main one
  const getSecondaryPromotions = () => {
    const secondaryPromos = [];
    for (let i = 1; i <= 4; i++) {
      const index = (currentMainIndex + i) % promotions.length;
      secondaryPromos.push(promotions[index]);
    }
    return secondaryPromos;
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <div className="error-state">{error}</div>;
  if (promotions.length === 0) return null;

  const mainPromo = promotions[currentMainIndex];
  const secondaryPromos = getSecondaryPromotions();

  return (
    <section className="featured-section">
      <div className="featured-slide active">
        <div className="featured-card">
          <div className="featured-main" onClick={() => handlePromoClick(mainPromo)}>
            <div className="time-badge">Motoring Specials</div>
            <img 
              src={mainPromo.image} 
              alt={`${mainPromo.make} ${mainPromo.model}`}
              className="featured-main-image"
            />
            <div className="featured-info-overlay">
              <h2 className="featured-title">{mainPromo.make} {mainPromo.model}</h2>
              <div className="featured-specs">
                {mainPromo.specs}
                <div className="featured-price">
                  <span className="price">{mainPromo.price}</span>
                  <span className="details">{mainPromo.priceType}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="featured-thumbnails">
            {secondaryPromos.map((promo) => (
              <div 
                key={promo.id}
                className="featured-secondary"
                onClick={() => handlePromoClick(promo)}
              >
                <img 
                  src={promo.image} 
                  alt={`${promo.make} ${promo.model}`}
                  loading="lazy"
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <PromotionContactForm 
        isOpen={isFormOpen}
        onClose={() => {
          setIsFormOpen(false);
          setSelectedPromo(null);
        }}
        promoDetails={selectedPromo}
      />
    </section>
  );
};

export default FeaturedPromotions;