import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import LoadingSpinner from './LoadingSpinner';
import './NewsPage.css';

const NewsPage = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticle = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://content.hsmdns.co.za/wp-json/wp/v2/posts/?slug=${slug}&_embed`
        );
        
        if (response.data && response.data.length > 0) {
          setArticle(response.data[0]);
        } else {
          throw new Error('Article not found');
        }
      } catch (err) {
        setError('Failed to fetch article');
        console.error('Error fetching article:', err);
      } finally {
        setLoading(false);
      }
    };

    if (slug) {
      fetchArticle();
    }
  }, [slug]);

  const formatArticleDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    });
  };

  if (loading) return <LoadingSpinner />;
  if (error || !article) return <div className="error-state">{error || 'Article not found'}</div>;

  return (
    <div className="news-page">
      <article className="article-content">
        <header className="article-header">
          {article._embedded?.['wp:featuredmedia']?.[0] && (
            <div className="featured-image-container">
              <img
                src={article._embedded['wp:featuredmedia'][0].source_url}
                alt={article.title.rendered}
                className="featured-image"
              />
            </div>
          )}
          <div className="article-meta">
            <span className="article-date">{formatArticleDate(article.date)}</span>
          </div>
          <h1 
            className="article-title"
            dangerouslySetInnerHTML={{ __html: article.title.rendered }}
          />
        </header>
        
        <div 
          className="article-body"
          dangerouslySetInnerHTML={{ __html: article.content.rendered }}
        />
      </article>
    </div>
  );
};

export default NewsPage;