import React, { useState } from 'react';
import axios from 'axios';
import './PromotionContactForm.css';

const PromotionContactForm = ({ isOpen, onClose, promoDetails }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    region: ''
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const regions = [
    'Eastern Cape',
    'Free State',
    'Gauteng',
    'KwaZulu-Natal',
    'Limpopo',
    'Mpumalanga',
    'Northern Cape',
    'North West',
    'Western Cape'
  ];

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }
    
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }
    
    if (!formData.mobile.trim()) {
      newErrors.mobile = 'Mobile number is required';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (validateForm() && !isSubmitting) {
      setIsSubmitting(true);

      try {
        const params = new URLSearchParams();
        params.append('action', 'send_lead');
        params.append('name', formData.name);
        params.append('email', formData.email);
        params.append('phone', formData.mobile);
        params.append('region', formData.region);
        params.append('message', `Enquiry about ${promoDetails.title} - ${promoDetails.specs} at ${promoDetails.price} ${promoDetails.details}`);
        params.append('send_alerts', '0');
        params.append('url', window.location.href);

        const response = await axios({
          method: 'post',
          url: 'https://dealer.carmag.co.za/app/app.php',
          data: params,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
            'Origin': 'https://dealer.carmag.co.za'
          }
        });

        if (response.data.success) {
          alert('Message sent successfully!');
          onClose();
        } else {
          throw new Error(response.data.message || 'Failed to send message');
        }
      } catch (error) {
        try {
          const formElement = document.createElement('form');
          formElement.method = 'POST';
          formElement.action = 'https://dealer.carmag.co.za/app/app.php';
          document.body.appendChild(formElement);

          Object.entries({
            action: 'send_lead',
            xxx: 'adrian',
            name: formData.name,
            email: formData.email,
            phone: formData.mobile,
            region: formData.region,
            message: `Enquiry about ${promoDetails.title} - ${promoDetails.specs} at ${promoDetails.price} ${promoDetails.details}`,
            send_alerts: '0'
          }).forEach(([key, value]) => {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = value;
            formElement.appendChild(input);
          });

          formElement.submit();
          document.body.removeChild(formElement);
          
          alert('Message sent successfully!');
          onClose();
        } catch (fallbackError) {
          console.error('Submission error:', fallbackError);
          alert('Error sending message. Please try again later.');
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="promotion-modal-backdrop" onClick={onClose}>
      <div className="promotion-modal-content" onClick={e => e.stopPropagation()}>
        <button className="promotion-modal-close" onClick={onClose}>&times;</button>
        
        <div className="promotion-modal-header">
          <h2>Enquire Now</h2>
          {promoDetails && (
            <div className="promotion-details">
              <h3>{promoDetails.title}</h3>
              {promoDetails.specs && <p>{promoDetails.specs}</p>}
              <p className="price">
                <span>{promoDetails.price}</span>
                <span className="details">{promoDetails.details}</span>
              </p>
            </div>
          )}
        </div>

        <form onSubmit={handleSubmit} className="promotion-contact-form">
          <div className="form-group">
            <input
              type="text"
              name="name"
              placeholder="Name*"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              disabled={isSubmitting}
            />
            {errors.name && <span className="error">{errors.name}</span>}
          </div>

          <div className="form-group">
            <input
              type="tel"
              name="mobile"
              placeholder="Mobile Number*"
              value={formData.mobile}
              onChange={(e) => setFormData({ ...formData, mobile: e.target.value })}
              disabled={isSubmitting}
            />
            {errors.mobile && <span className="error">{errors.mobile}</span>}
          </div>

          <div className="form-group">
            <input
              type="email"
              name="email"
              placeholder="Email*"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              disabled={isSubmitting}
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>

          <div className="form-group">
            <select
              value={formData.region}
              onChange={(e) => setFormData({ ...formData, region: e.target.value })}
              disabled={isSubmitting}
            >
              <option value="">Select Region</option>
              {regions.map((region) => (
                <option key={region} value={region}>{region}</option>
              ))}
            </select>
          </div>

          <button 
            type="submit" 
            className="submit-button"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Sending...' : 'Submit Enquiry'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default PromotionContactForm;