import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import LoadingSpinner from './LoadingSpinner';
import './DealerListingPage.css';

const DealerListingPage = () => {
  const { province } = useParams();
  const navigate = useNavigate();
  const [dealers, setDealers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDealers = async () => {
      setLoading(true);
      try {
        // Format province name for display
        const formattedProvince = province
          .split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ');

        const response = await fetch(
          `https://dealer.carmag.co.za/api.php?getdealers=1&Region=${encodeURIComponent(formattedProvince)}`
        );
        const data = await response.json();
        
        if (data.listings) {
          // Process the dealers to ensure unique entries
          const dealersList = Array.isArray(data.listings) ? data.listings : [data.listings];
          const uniqueDealers = dealersList.reduce((acc, dealer) => {
            const existingDealer = acc.find(d => d.DealerID === dealer.DealerID);
            if (!existingDealer) {
              acc.push({
                ...dealer,
                DealerID: dealer.DealerID + '-' + dealer.city // Make key unique by combining ID and city
              });
            }
            return acc;
          }, []);
          
          setDealers(uniqueDealers);
        } else {
          setDealers([]);
        }
      } catch (err) {
        setError('Failed to fetch dealers');
        console.error('Error:', err);
      }
      setLoading(false);
    };

    fetchDealers();
  }, [province]);

  const handleDealerClick = (dealer) => {
    const dealerSlug = dealer.dealershipname.toLowerCase().replace(/\s+/g, '-');
    navigate(`/car-dealership/${dealerSlug}`, { state: { dealerInfo: dealer } });
  };

  const handleBackClick = () => {
    navigate('/find-dealer');
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <div className="error-state">{error}</div>;

  // Format province name for display
  const displayProvince = province
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

  return (
    <div className="dealer-listing-page">
      <div className="dealer-listing-header">
        <button 
          className="back-button" 
          onClick={handleBackClick}
          aria-label="Back to dealer search"
        >
          <ArrowLeft size={20} />
          <span>Back</span>
        </button>
        <h1>Dealerships in {displayProvince}</h1>
        <p>{dealers.length} dealers found</p>
      </div>

      <div className="dealer-grid">
        {dealers.map((dealer) => (
          <div
            key={dealer.DealerID}
            className="dealer-pill"
            onClick={() => handleDealerClick(dealer)}
          >
            <h2>{dealer.dealershipname}</h2>
            <div className="dealer-info">
              <p>{dealer.city}</p>
              <span className="dealer-count">({dealer.total || 0} cars)</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DealerListingPage;