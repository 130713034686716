import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ThemeContext } from '../contexts/ThemeContext';
import { useRegion } from '../contexts/RegionContext';
import { Search, ChevronDown, Menu, X } from 'lucide-react';
import lightLogo from '../assets/images/autodealer-logo-light.png';
import darkLogo from '../assets/images/autodealer-logo-dark.png';
import './Navbar.css';
import axios from 'axios';

const MobileSearchOverlay = ({ isOpen, onClose, selectedRegion, updateRegion, regions, navigate }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [makeSuggestions, setMakeSuggestions] = useState([]);
  const [modelSuggestions, setModelSuggestions] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    const fetchMakes = async () => {
      try {
        const response = await axios.get('https://dealer.carmag.co.za/app/ajax.php?getmakes');
        if (Array.isArray(response.data)) {
          setMakeSuggestions(response.data);
        }
      } catch (error) {
        console.error('Error fetching car makes:', error);
      }
    };
    fetchMakes();
  }, []);

  useEffect(() => {
    const makeTag = selectedTags.find(tag => tag.type === 'make');
    if (makeTag) {
      fetchModels(makeTag.id);
    }
  }, [selectedTags]);

  const fetchModels = async (makeId) => {
    try {
      const response = await axios.get(
        `https://dealer.carmag.co.za/app/ajax.php?getranges=1&ID=${makeId}`
      );
      if (Array.isArray(response.data)) {
        setModelSuggestions(response.data);
      }
    } catch (error) {
      console.error('Error fetching car models:', error);
    }
  };

  useEffect(() => {
    if (searchTerm) {
      if (!selectedTags.some(tag => tag.type === 'make')) {
        setFilteredSuggestions(
          makeSuggestions.filter(make =>
            make.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      } else {
        setFilteredSuggestions(
          modelSuggestions.filter(model =>
            model.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      }
    } else {
      setFilteredSuggestions([]);
    }
  }, [searchTerm, makeSuggestions, modelSuggestions, selectedTags]);

  const handleSearch = () => {
    const makeTag = selectedTags.find(tag => tag.type === 'make');
    const modelTag = selectedTags.find(tag => tag.type === 'model');

    let searchUrl = '/cars-for-sale';
    let params = new URLSearchParams();

    if (makeTag) {
      params.append('make', makeTag.name);
      if (modelTag) {
        params.append('model', modelTag.name);
      }
    }

    if (selectedRegion !== 'Region') {
      params.append('region', selectedRegion);
    }

    if (params.toString()) {
      searchUrl += `?${params.toString()}`;
    }

    navigate(searchUrl);
    onClose();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (searchTerm && !selectedTags.find(tag => tag.name.toLowerCase() === searchTerm.trim().toLowerCase())) {
        const matchedMake = makeSuggestions.find(make =>
          make.name.toLowerCase() === searchTerm.trim().toLowerCase()
        );
        const matchedModel = modelSuggestions.find(model =>
          model.name.toLowerCase() === searchTerm.trim().toLowerCase()
        );

        if (matchedMake) {
          setSelectedTags([...selectedTags, { ...matchedMake, type: 'make' }]);
          setSearchTerm('');
        } else if (matchedModel && selectedTags.some(tag => tag.type === 'make')) {
          setSelectedTags([...selectedTags, { ...matchedModel, type: 'model' }]);
          setSearchTerm('');
        }
      } else if (selectedTags.length > 0) {
        handleSearch();
      }
    }
  };

  const handleSuggestionClick = (suggestion) => {
    if (!selectedTags.find(tag => tag.name.toLowerCase() === suggestion.name.toLowerCase())) {
      if (!selectedTags.some(tag => tag.type === 'make')) {
        setSelectedTags([...selectedTags, { ...suggestion, type: 'make' }]);
      } else {
        setSelectedTags([...selectedTags, { ...suggestion, type: 'model' }]);
      }
      setSearchTerm('');
    }
  };

  const removeTag = (type) => {
    setSelectedTags(selectedTags.filter(tag => tag.type !== type));
    if (type === 'make') {
      setModelSuggestions([]);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="mobile-search-overlay">
      <div className="search-bar">
        <div className="region-select-container">
          <select
            className="region-select"
            value={selectedRegion}
            onChange={(e) => updateRegion(e.target.value)}
            aria-label="Select region"
          >
            {regions.map((region) => (
              <option key={region} value={region}>
                {region}
              </option>
            ))}
          </select>
          <ChevronDown size={16} className="region-select-arrow" />
        </div>

        <div className="search-content">
          <div className="search-bar-content">
            <div className="tags">
              {selectedTags.map(tag => (
                <span key={tag.id} className="tag">
                  {tag.name}
                  <button
                    onClick={() => removeTag(tag.type)}
                    className="tag-close"
                  >
                    ×
                  </button>
                </span>
              ))}
            </div>
            <div className="search-input-wrapper">
              {selectedTags.length < 2 && (
                <input
                  type="text"
                  placeholder="What are you looking for?"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              )}
            </div>
          </div>
          <button className="search-button" onClick={handleSearch}>
            <Search size={20} />
          </button>
        </div>
      </div>

      {filteredSuggestions.length > 0 && (
        <div className="suggestion-list">
          {filteredSuggestions.slice(0, 5).map((suggestion, index) => (
            <div
              key={index}
              className="suggestion-item"
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const Navbar = () => {
  const { theme } = useContext(ThemeContext);
  const { selectedRegion, updateRegion } = useRegion();
  const location = useLocation();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');
  const [makeSuggestions, setMakeSuggestions] = useState([]);
  const [modelSuggestions, setModelSuggestions] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);
  const [regions] = useState([
    'Region',
    'Eastern Cape',
    'Free State',
    'Gauteng',
    'KwaZulu-Natal',
    'Limpopo',
    'Mpumalanga',
    'Northern Cape',
    'North West',
    'Western Cape'
  ]);

  const logo = theme === 'light' ? lightLogo : darkLogo;

  useEffect(() => {
    setIsMobileMenuOpen(false);
    setIsMobileSearchOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    const fetchMakes = async () => {
      try {
        const response = await axios.get('https://dealer.carmag.co.za/app/ajax.php?getmakes');
        if (Array.isArray(response.data)) {
          setMakeSuggestions(response.data);
        }
      } catch (error) {
        console.error('Error fetching car makes:', error);
      }
    };
    fetchMakes();
  }, []);

  useEffect(() => {
    const makeTag = selectedTags.find(tag => tag.type === 'make');
    if (makeTag) {
      fetchModels(makeTag.id);
    }
  }, [selectedTags]);

  const fetchModels = async (makeId) => {
    try {
      const response = await axios.get(
        `https://dealer.carmag.co.za/app/ajax.php?getranges=1&ID=${makeId}`
      );
      if (Array.isArray(response.data)) {
        setModelSuggestions(response.data);
      }
    } catch (error) {
      console.error('Error fetching car models:', error);
    }
  };

  useEffect(() => {
    if (searchTerm) {
      if (!selectedTags.some(tag => tag.type === 'make')) {
        setFilteredSuggestions(
          makeSuggestions.filter(make =>
            make.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      } else {
        setFilteredSuggestions(
          modelSuggestions.filter(model =>
            model.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      }
    } else {
      setFilteredSuggestions([]);
    }
  }, [searchTerm, makeSuggestions, modelSuggestions, selectedTags]);

  const isActive = (path) => {
    if (path === '/cars-for-sale') {
      return location.pathname.startsWith('/cars-for-sale') || 
             location.pathname.startsWith('/car-for-sale') ? 'active' : '';
    } else if (path === '/news') {
      return location.pathname.startsWith('/news') ? 'active' : '';
    }
    return location.pathname === path ? 'active' : '';
  };

  const handleSearch = () => {
    const makeTag = selectedTags.find(tag => tag.type === 'make');
    const modelTag = selectedTags.find(tag => tag.type === 'model');

    let searchUrl = '/cars-for-sale';
    let params = new URLSearchParams();

    if (makeTag) {
      params.append('make', makeTag.name);
      if (modelTag) {
        params.append('model', modelTag.name);
      }
    }

    if (selectedRegion !== 'Region') {
      params.append('region', selectedRegion);
    }

    if (params.toString()) {
      searchUrl += `?${params.toString()}`;
    }

    navigate(searchUrl);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (searchTerm && !selectedTags.find(tag => tag.name.toLowerCase() === searchTerm.trim().toLowerCase())) {
        const matchedMake = makeSuggestions.find(make =>
          make.name.toLowerCase() === searchTerm.trim().toLowerCase()
        );
        const matchedModel = modelSuggestions.find(model =>
          model.name.toLowerCase() === searchTerm.trim().toLowerCase()
        );

        if (matchedMake) {
          setSelectedTags([...selectedTags, { ...matchedMake, type: 'make' }]);
          setSearchTerm('');
        } else if (matchedModel && selectedTags.some(tag => tag.type === 'make')) {
          setSelectedTags([...selectedTags, { ...matchedModel, type: 'model' }]);
          setSearchTerm('');
        }
      } else if (selectedTags.length > 0) {
        handleSearch();
      }
    }
  };

  const handleSuggestionClick = (suggestion) => {
    if (!selectedTags.find(tag => tag.name.toLowerCase() === suggestion.name.toLowerCase())) {
      if (!selectedTags.some(tag => tag.type === 'make')) {
        setSelectedTags([...selectedTags, { ...suggestion, type: 'make' }]);
      } else {
        setSelectedTags([...selectedTags, { ...suggestion, type: 'model' }]);
      }
      setSearchTerm('');
    }
  };

  const removeTag = (type) => {
    setSelectedTags(selectedTags.filter(tag => tag.type !== type));
    if (type === 'make') {
      setModelSuggestions([]);
    }
  };

  const handleRegionChange = (e) => {
    const newRegion = e.target.value;
    updateRegion(newRegion);

    const searchParams = new URLSearchParams(location.search);
    if (newRegion !== 'Region') {
      searchParams.set('region', newRegion);
    } else {
      searchParams.delete('region');
    }

    navigate({
      pathname: location.pathname,
      search: searchParams.toString() ? `?${searchParams.toString()}` : ''
    });
  };

  return (
    <header className="header">
      <div className="header-content">
        <Link to="/" className="logo">
          <img src={logo} alt="Auto Dealer Logo" className="logo-image" />
        </Link>

        <div className="mobile-buttons">
          <button 
            className="mobile-button"
            onClick={() => setIsMobileSearchOpen(!isMobileSearchOpen)}
            aria-label="Search"
          >
            <Search size={24} />
          </button>
          <button 
            className="mobile-button"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            aria-label="Toggle menu"
          >
            {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>

        <div className="nav-content">
          <nav className="nav-links">
            <Link to="/" className={isActive('/')}>Home</Link>
            <Link to="/cars-for-sale" className={isActive('/cars-for-sale')}>Cars for sale</Link>
            <Link to="/sell-car" className={isActive('/sell-car')}>Sell a Car</Link>
            <Link to="/find-dealer" className={isActive('/find-dealer')}>Find a Dealer</Link>
            <Link to="/news" className={isActive('/news')}>Motoring News</Link>
          </nav>

          <div className="search-section">
            <div className="search-bar">
              <div className="region-select-container">
                <select
                  className="region-select"
                  value={selectedRegion}
                  onChange={handleRegionChange}
                  aria-label="Select region"
                >
                  {regions.map((region) => (
                    <option key={region} value={region}>
                      {region}
                    </option>
                  ))}
                </select>
                <ChevronDown size={16} className="region-select-arrow" />
              </div>

              <div className="search-content">
                <div className="search-bar-content">
                  <div className="tags">
                    {selectedTags.map(tag => (
                      <span key={tag.id} className="tag">
                        {tag.name}
                        <button
                          onClick={() => removeTag(tag.type)}
                          className="tag-close"
                        >
                          ×
                        </button>
                      </span>
                    ))}
                  </div>
                  <div className="search-input-wrapper">
                    {selectedTags.length < 2 && (
                      <input
                        type="text"
                        placeholder="Search car make & model"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyDown={handleKeyDown}
                      />
                    )}
                  </div>
                </div>
                <button className="search-button" onClick={handleSearch}>
                  <Search size={20} />
                </button>
              </div>
            </div>

            {filteredSuggestions.length > 0 && (
              <div className="suggestion-list">
                {filteredSuggestions.slice(0, 5).map((suggestion, index) => (
                  <div
                    key={index}
                    className="suggestion-item"
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className={`mobile-menu ${isMobileMenuOpen ? 'open' : ''}`}>
          <nav className="mobile-nav-links">
            <Link to="/">Home</Link>
            <Link to="/cars-for-sale">Cars for sale</Link>
            <Link to="/sell-car">Sell a Car</Link>
            <Link to="/find-dealer">Find a Dealer</Link>
            <Link to="/news">Motoring News</Link>
          </nav>
        </div>

        <MobileSearchOverlay 
          isOpen={isMobileSearchOpen}
          onClose={() => setIsMobileSearchOpen(false)}
          selectedRegion={selectedRegion}
          updateRegion={updateRegion}
          regions={regions}
          navigate={navigate}
        />
      </div>
    </header>
  );
};

export default Navbar;