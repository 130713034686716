import promotionsData from '../data/promotions.json';

export const getPromotions = async () => {
  // In a real application, this would be an API call
  // For now, we'll simulate an API call with a Promise
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(promotionsData.promotions);
    }, 100);
  });
};

export const getPromotionById = async (id) => {
  const promotions = await getPromotions();
  return promotions.find(promo => promo.id === id);
};