// src/hooks/useSwipeNavigation.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useSwipeNavigation = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    let touchStartX = 0;
    let touchEndX = 0;
    
    const handleTouchStart = (e) => {
      touchStartX = e.targetTouches[0].clientX;
    };
    
    const handleTouchMove = (e) => {
      touchEndX = e.targetTouches[0].clientX;
    };
    
    const handleTouchEnd = () => {
      const swipeThreshold = 100; // Minimum distance for a swipe
      const swipeDistance = touchEndX - touchStartX;
      
      // Only trigger if swipe starts from left edge (within 20px)
      if (touchStartX < 20 && swipeDistance > swipeThreshold) {
        navigate(-1); // Go back one page
      }
      
      // Reset values
      touchStartX = 0;
      touchEndX = 0;
    };
    
    // Only add listeners if on mobile
    if (window.innerWidth <= 768) {
      document.addEventListener('touchstart', handleTouchStart);
      document.addEventListener('touchmove', handleTouchMove);
      document.addEventListener('touchend', handleTouchEnd);
    }
    
    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [navigate]);
};

export default useSwipeNavigation;